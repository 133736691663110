/* eslint-disable camelcase */
import get from "lodash/get";
import React from "react";
import { useTranslation } from "next-i18next";
import useUserBookmark from "../../hooks/useUserBookmark";
import getExcerpt, {
  isEventArticlePlatform,
  isUnapprovedPlatform,
} from "../../utilities/common";
import { displayShortDateRange } from "../../utilities/date";
import HorizontalCard from "./horizontalCard";
import LongCard from "./longCard";
import NormalCard from "./normalCard";
import { getRibbon } from "./utility";
import Modal from "../modal";
import { useRouter } from "next/router";
import PlaceHolderImage from "../../public/images/placeholder2.png";

export interface Props {
  cardType?: "normal" | "long" | "horizontal";
  eventData: any;
  attributionToken?: string;
  gaClickLabel?: string;
}

export interface EventCardProps {
  ribbon: {
    ribbonValue: string;
    ribbbonLabel: any;
    ribbonClass: string;
  };
  featureImage: string | StaticImageData;
  dateRangeText: string;
  title: string;
  excerpt: string;
  venueName?: string;
  isBookmarked: boolean;
  isEnableBookmark: boolean;
  toggleBookmark: () => Promise<void>;
  masterCategoryName: string;
  priceLabel: string;
  redirectToEventUrl: boolean;
  href: string;
  as: string;
  target: string;
  uid: string;
  gaClickLabel?: string;
  isSponsoredEvent?: boolean;
}

export default function EventCard(props: Props) {
  const {
    cardType = "normal",
    eventData,
    attributionToken,
    gaClickLabel,
  } = props;
  const {
    i18n: { language: lang },
    t,
  } = useTranslation();
  const router = useRouter();
  const {
    checkIsBookmarked,
    canBookmark,
    toggleBookmark: _toggleBookmark,
  } = useUserBookmark();
  const [isEnableBookmark, setIsEnableBookmark] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const {
    uid,
    price,
    gallery_images: galleryImages,
    title,
    start_datetime: startDatetime,
    end_datetime: endDatetime,
    venueName,
    venue,
    tags,
    category,
    categories: _categories,
    slug,
    platform_source,
    event_url,
    logo_url,
    _excerpt,
    excerpt,
    desc,
    isSponsoredEvent,
  } = eventData;

  const toggleBookmark = React.useCallback(async () => {
    if (!canBookmark) {
      setIsModalOpen(true);
      return;
    }
    if (isEnableBookmark) {
      setIsEnableBookmark(false);
      await _toggleBookmark(uid);
      setIsEnableBookmark(true);
    }
  }, [uid, isEnableBookmark, canBookmark, _toggleBookmark]);

  const ribbon = getRibbon(eventData, t);

  let featureImage: string | StaticImageData = logo_url as string;
  if (!featureImage) {
    const eventImages: string[] = galleryImages ? galleryImages.split(",") : [];
    featureImage = get(eventImages, "[0]", PlaceHolderImage);
    // featureImage = _.get(eventImages, "[0]", "/images/placeholder2.png");
  }

  const dateRangeText = displayShortDateRange(startDatetime, endDatetime);
  const finalExcerpt = (excerpt || _excerpt || getExcerpt(desc)) as string;

  let masterCategoryName: string = category;
  if (!masterCategoryName) {
    const categories = tags || _categories;
    const masterCategoryList = (categories || []).filter((c: any) => c.type === "master");
    masterCategoryName = get(masterCategoryList, "[0].name", null);
    if (masterCategoryName)
      masterCategoryName = t(`category.${masterCategoryName.toLowerCase()}`);
  }

  const redirectToEventUrl =
    isEventArticlePlatform(platform_source) ||
    isUnapprovedPlatform(platform_source);

  const hrefParams = attributionToken ? `?rtoken=${attributionToken}` : "";
  const href: string = redirectToEventUrl
    ? event_url
    : `/events/[id]/${hrefParams}`;
  const as = redirectToEventUrl
    ? undefined
    : `/events/${slug || uid}/${hrefParams}`;
  const target = redirectToEventUrl ? "_target" : "_self";
  const isBookmarked = checkIsBookmarked(uid);
  const priceLabel = price > 0 ? t("price.paid") : t("price.free");
  const cardProps: EventCardProps = {
    ribbon,
    featureImage,
    dateRangeText,
    title,
    excerpt: finalExcerpt,
    venueName: venueName || venue?.name,
    isBookmarked,
    isEnableBookmark,
    toggleBookmark,
    masterCategoryName,
    priceLabel,
    redirectToEventUrl,
    href,
    as,
    target,
    uid,
    gaClickLabel,
    isSponsoredEvent
  };

  const EventCard = React.useMemo(() => {
    if (cardType === "normal") return <NormalCard {...cardProps} />;
    if (cardType === "horizontal") return <HorizontalCard {...cardProps} />;
    if (cardType === "long") return <LongCard {...cardProps} />;
    return <></>;
  }, [cardType, cardProps]);

  return (
    <>
      {EventCard}
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        message={t("common:message.loginFirst")}
        confirmButtonText={t("common:buttonLabel.login")}
        closeButtonText={t("common:buttonLabel.close")}
        onConfirmButtonClick={() => router.push("/users/login")}
      />
    </>
  );
}
