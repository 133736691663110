/* eslint-disable camelcase */
import moment from "moment";

export const getRibbon = (event, t) => {
  const { status, end_datetime, promotion, enrol_type } = event;
  let ribbonValue = null;
  let ribbonClass = null;
  if (status === "expired") {
    ribbonValue = "expired";
  }
  const now = moment();
  const endDatetime = moment(end_datetime);
  const daysLeft = endDatetime.diff(now, "days");
  if (daysLeft < 0) {
    ribbonValue = "expired";
  } else if (daysLeft === 0) {
    ribbonValue = "endToday";
  } else if (daysLeft <= 7) {
    ribbonValue = "lastWeek";
  }

  switch (ribbonValue) {
    case "expired":
      ribbonClass = "ribbon-expired";
      break;
    case "endToday":
      ribbonClass = "ribbon-urgent";
      break;
    case "lastWeek":
      ribbonClass = "ribbon-high";
      break;
    default:
      break;
  }

  if (!ribbonValue) {
    if (promotion) {
      ribbonValue = 'exclusivePromotion';
      ribbonClass = "ribbon-urgent";
    } else if (enrol_type === 'url' || enrol_type === 'playwhat') {
      ribbonValue = 'applyNow';
      ribbonClass = "ribbon-high";
    }
  }
  
  return {
    ribbonValue,
    ribbbonLabel: ribbonValue ? t(`status.${ribbonValue}`) : null,
    ribbonClass,
  };
}


export default getRibbon;