import Link from "next/link";
import Image from "../image";
import { EventCardProps } from "./";
import clsx from "clsx";

export default function EventHorizontalCard(props: EventCardProps) {
  const {
    ribbon,
    featureImage,
    dateRangeText,
    title,
    excerpt,
    venueName,
    isBookmarked,
    isEnableBookmark,
    toggleBookmark,
    masterCategoryName,
    redirectToEventUrl,
    href,
    as,
    target,
    uid,
    gaClickLabel
  } = props;

  const gaTrackingProps = gaClickLabel ? {
    "data-click-label": gaClickLabel,
    "data-click-value": uid
  } : {};

  return (
    <div className="card event-horizontal-card">
      <Link href={href} as={as}>
        <a
          className="card-image"
          target={target}
          rel={redirectToEventUrl ? "nofollow" : ""}
          {...gaTrackingProps}
        >
          <Image isBackgroundImage url={featureImage} alt="event image" />
        </a>
      </Link>

      <div className="card-main">
        <Link href={href} as={as}>
          <a
            target={target}
            className={redirectToEventUrl ? "external-event-link" : ""}
            rel={redirectToEventUrl ? "nofollow" : ""}
            {...gaTrackingProps}
          >
             <p className="card-title">{title}</p>
            {redirectToEventUrl && (
              <Image width={20} height={20} url="/icons/open-outline.svg" alt="" />
            )}
          </a>
        </Link>

        <p className="excerpt">{excerpt}</p>

        <div className="card-main-bottom card-main-meta">
          {masterCategoryName && (
            <div className="card-main-meta-item">
              <Image
                className="svg-icon"
                width={12}
                height={12}
                url="/icons/pricetags-outline.svg"
                alt=""
              />
              <span className="icon-text">{masterCategoryName}</span>
            </div>
          )}
          {dateRangeText && (
            <div className="card-main-meta-item">
              <Image
                className="svg-icon"
                width={12}
                height={12}
                url="/icons/calendar-outline.svg"
                alt=""
              />
              <span className="icon-text">{dateRangeText}</span>
            </div>
          )}
          <button
            type="button"
            className={clsx("bookmark", isBookmarked && "active")}
            disabled={!isEnableBookmark}
            onClick={toggleBookmark}
            aria-label="bookmark"
            data-click-label="bookmark_event"
            data-click-value={uid}
          >
            <Image
              className="svg-icon"
              width={16}
              height={16}
              url={
                isBookmarked
                  ? "/icons/bookmark.svg"
                  : "/icons/bookmark-outline.svg"
              }
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
  );
}
