/* eslint-disable camelcase */
import Link from "next/link";
import Image from "../image";
import { EventCardProps } from "./";

export default function EventLongCard(props: EventCardProps) {
  const {
    ribbon,
    featureImage,
    dateRangeText,
    title,
    excerpt,
    venueName,
    isBookmarked,
    isEnableBookmark,
    toggleBookmark,
    masterCategoryName,
    redirectToEventUrl,
    href,
    as,
    target,
    uid,
    gaClickLabel
  } = props;

  const gaTrackingProps = gaClickLabel ? {
    "data-click-label": gaClickLabel,
    "data-click-value": uid
  } : {};

  return (
    <div className="card event-long-card">
      <Link href={href} as={as}>
        <a target={target} className="card-header" {...gaTrackingProps}>
          <Image isBackgroundImage url={featureImage} alt="event image" />
        </a>
      </Link>

      <div className="card-main">
        <Link href={href} as={as}>
          <a
            target={target}
            className={redirectToEventUrl ? "external-event-link" : ""}
            {...gaTrackingProps}
          >
            <p className="card-title">{title}</p>
            {redirectToEventUrl && (
              <Image width={20} height={20} url="/icons/open-outline.svg" alt="" />
            )}
          </a>
        </Link>
        <p className="excerpt">{excerpt}</p>
      </div>
    </div>
  );
}
