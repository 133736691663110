import clsx from "clsx";
import Link from "next/link";
import React from "react";
import { useTranslation } from "react-i18next";
import Image from "../image";
import { EventCardProps } from "./";
import styles from "./card.module.scss";

export default function EventCard(props: EventCardProps) {
  const {
    ribbon,
    featureImage,
    dateRangeText,
    title,
    excerpt,
    venueName,
    isBookmarked,
    isEnableBookmark,
    toggleBookmark,
    masterCategoryName,
    priceLabel,
    redirectToEventUrl,
    href,
    as,
    target,
    uid,
    gaClickLabel,
    isSponsoredEvent,
  } = props;

  const { t } = useTranslation();
  const gaTrackingProps = gaClickLabel
    ? {
        "data-click-label": gaClickLabel,
        "data-click-value": uid,
      }
    : {};

  return (
    <div className="card event-card">
      {ribbon && ribbon.ribbonValue && (
        <div className={`ribbon ribbon-top-right ${ribbon.ribbonClass}`}>
          <span>{ribbon.ribbbonLabel}</span>
        </div>
      )}
      <div className="card-header">
        <Image isBackgroundImage url={featureImage} alt="event image" />
        <div className="price-tag">{priceLabel}</div>
        <button
          type="button"
          className={clsx("bookmark", isBookmarked && "active")}
          disabled={!isEnableBookmark}
          onClick={toggleBookmark}
          aria-label="bookmark"
          data-click-label="bookmark_event"
          data-click-value={uid}
        >
          <Image
            className="svg-icon"
            width={18}
            height={18}
            url={
              isBookmarked
                ? "/icons/bookmark.svg"
                : "/icons/bookmark-outline.svg"
            }
            alt=""
          />
        </button>
      </div>

      <div className="card-main">
        <div className="card-main-meta">
          {dateRangeText && (
            <>
              <Image
                className="svg-icon"
                width={12}
                height={12}
                url="/icons/calendar-outline.svg"
                alt=""
              />
              <span className="icon-text">{dateRangeText}</span>
            </>
          )}
          {venueName && (
            <>
              <Image
                className="svg-icon"
                width={12}
                height={12}
                url="/icons/location-outline.svg"
                alt=""
              />
              <span className="icon-text">{venueName}</span>
            </>
          )}
        </div>

        <Link href={href} as={as}>
          <a
            target={target}
            className={redirectToEventUrl ? "external-event-link" : ""}
            {...gaTrackingProps}
          >
            <p className="card-title">{title}</p>
            {redirectToEventUrl && (
              <Image
                width={20}
                height={20}
                url="/icons/open-outline.svg"
                alt=""
              />
            )}
          </a>
        </Link>

        <div className="card-main-bottom card-main-meta">
          {masterCategoryName && (
            <div className="card-main-meta-item">
              <Image
                className="svg-icon"
                width={12}
                height={12}
                url="/icons/pricetags-outline.svg"
                alt=""
              />
              <span className="icon-text">{masterCategoryName}</span>
            </div>
          )}
        </div>
      </div>
      {isSponsoredEvent && (
        <small className={styles.sponsoredLabel}>{t("sponsored")}</small>
      )}
    </div>
  );
}
